import { Routes, Route, Navigate } from "react-router-dom";
// import RecuperarSenha from "../paginas/RecuperarSenha/recuperarsenha.jsx";
// import RecuperarSenha from "../pages/RecuperarSenha/recuperarsenha.jsx";
import Login from './../pages/Login/login';
import PreLoad from "../pages/Preload/preload";

export default function AuthRoutes() {

    return (
        <Routes>
            <Route path='/' element={<PreLoad />} />
            <Route path='/login' element={<Login />} />
            {/* <Route path='/recuperarsenha' element={<RecuperarSenha />} /> */}
            {/* <Route path='/recuperarsenha' element={<RecuperarSenha />} /> */}
            <Route path='*' element={<Navigate to={'/'} />} />
        </Routes>
    );

}