import React from 'react';
import { toast } from 'react-toastify';

//estilo da mensagem se faz aqui
const Msg = (props) => {
  
  return (
      //a cores é opcional, pois o css apenas ignora se não houver valor;
      
      <div style={{color:`${props.color}`}}>
        <h1><strong>{props.titulo}</strong></h1>
        <div>{props.mensagem}</div>
      </div>
    )
}

/*AVISO:
  SE QUISER APAGAR TODAS OS ALERTAS AO MESMO TEMPO USAR toast.dismiss()
*/

/**
 * 
 * @param {string} tipo 'sucesso' | 'erro' | 'alerta' - string
 * @param {string} mensagem - string
 * @param {string} titulo - string
 * @param {number} tempo milisegundos ex: 1s = 1000 - number
 * @returns um método
 */

function AlertaDeMensagem (tipo, mensagem, titulo, tempo){
  
   let tituloVerificado = titulo;
   let mensagemVerificado = mensagem;
   let tipoVerificado = tipo;
   let tempoVerificado = tempo;

  try {
      //valores padrões
  if(!tituloVerificado){
    tituloVerificado = '';

  }else if(!mensagemVerificado){
    mensagemVerificado = '';

  }else if(!tempoVerificado){
      tempoVerificado = 3000;
  } 
  
   //recebe o tipo em string e define qual mensagem renderizará
    const mensagemTipo = {
      sucesso: (titulo, mensagem, tempo)=> {
        toast.success(<Msg titulo={titulo}  mensagem={mensagem}/>, {
          autoClose: tempo,
        }) 
      },
      erro: (titulo, mensagem, tempo)=> {
        toast.error(<Msg titulo={titulo}  mensagem={mensagem}/>, {
          autoClose: tempo,
        })
      },
      alerta: (titulo, mensagem, tempo)=> {
        toast.warn(<Msg titulo={titulo}  mensagem={mensagem} color='#000'/>, {
          autoClose: tempo,
        })
      },
    }
  
    /**caso não haja valores, terá valores padrão */
    return mensagemTipo[`${tipoVerificado}`]( tituloVerificado, mensagemVerificado, tempoVerificado);
    
  } catch (error) {
    console.error(error)
  }
}

export default AlertaDeMensagem;