import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloClientes'
import avatar from '../../img/kleberson.png'
import api from './../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import Pesquisa from '../../components/pesquisa/pesquisa';
import * as Fa from "react-icons/fa";
import InputKweb from '../../components/InputPersonalizado/input_personalizavel';
import axios from 'axios';
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';
import noimage from '../../img/profile.png'
import Resizer from "react-image-file-resizer";

export default function Clientes() {
  const { user, setPesquisaAtiva, Spinner } = useContext(AuthContext)
  const [clienteSelecionado, setClienteSelecionado] = useState(null)
  const [listaClientes, setListaClientes] = useState([])
  const [listaClientes2, setListaClientes2] = useState([])
  const [adicionarCliente, setAdicionarCliente] = useState(false)
  const [imgUsuarioSalvar, setImgUsuarioSalvar] = useState(null);

  const [cep, setCep] = useState('')

  useEffect(() => {
    if (cep.replaceAll('-', '').length == 8) {
      buscarCep(cep)
    }
  }, [cep])

  //entra aqui quando é edição de cliente
  useEffect(() => {
    if (clienteSelecionado) {
      setImgUsuarioSalvar(clienteSelecionado.logo)
      for (const key in clienteSelecionado) {
        const valor = clienteSelecionado[key];
        try { document.getElementById(key).value = valor } catch (error) { null }
      }
    }
  }, [clienteSelecionado])

  useEffect(() => {
    if (!adicionarCliente) {
      setClienteSelecionado(null)
      setImgUsuarioSalvar(null)
    }
  }, [adicionarCliente])

  async function buscarCep(cep) {

    let url = `https://viacep.com.br/ws/${cep}/json`

    // Função assíncrona para fazer a requisição

    try {
      // Faz a requisição GET utilizando Axios e aguarda a resposta
      const response = await axios.get(url);
      // Manipula os dados recebidos
      const dados = response.data
      if (dados) {

        if (dados.erro) {
          document.querySelector(`#logradouro`).value = ''
          document.querySelector(`#cidade`).value = ''
          document.querySelector(`#estado`).value = ''
          document.querySelector(`#bairro`).value = ''
          return AlertaDeMensagem('alerta', 'Verifique o cep digitado e tente novamente.', 'CEP INVÁLIDO', 4000)
        }

        document.querySelector(`#logradouro`).value = dados.logradouro
        document.querySelector(`#cidade`).value = dados.localidade
        document.querySelector(`#estado`).value = dados.uf
        document.querySelector(`#bairro`).value = dados.bairro

      } else {
        AlertaDeMensagem('alerta', 'Falha na consulta do endereço.', 'CEP INVÁLIDO', 4000)
      }
      // Aqui você pode fazer o que quiser com os dados, como exibir na página, etc.
    } catch (error) {
      // Captura e trata qualquer erro ocorrido durante a requisição
      AlertaDeMensagem('alerta', 'Erro ao obter os dados do CEP:', 'Erro', 4000)
      console.error('Erro ao obter os dados do CEP:', error);
    }

  }

  function editarCliente(cliente) {
    setClienteSelecionado(cliente)
    setAdicionarCliente(true)
  }

  useEffect(() => {
    setPesquisaAtiva(false)
    buscarClientes()
  }, [])

  function filtraLista(texto) {
    let novoArray = listaClientes2.filter(
      e => e.nome.toLowerCase().includes(texto.toLowerCase()) ||
        e.sobrenome.toLowerCase().includes(texto.toLowerCase()) ||
        e.id_cliente.toString().includes(texto)
    )
    setListaClientes(novoArray)
  }
  async function buscarClientes() {

    try {
      const res = await api.get(`/clientes/clientes`)
      if (res.data.status) {
        setListaClientes(res.data.resultado)
        setListaClientes2(res.data.resultado)
      } else {
        setListaClientes([])
        setListaClientes2([])
        AlertaDeMensagem('alerta', 'Não existe nenhum cliente cadastrado, faça seu primeiro cadastro', 'ATENÇÃO', 4000)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }
  async function gravar() {
    let elementos = document.querySelectorAll(`.valores [name]`)
    let fildsvalores = { logo: imgUsuarioSalvar }

    for (const fild of elementos) {
      let valor = document.querySelector(`#${fild.id}`).value
      fildsvalores = {
        ...fildsvalores,
        [fild.name]: valor
      }
    }

    let obrigatorio = [
      'nome',
      'sobrenome',
      'cpf',
      'data_nascimento',
      'profissao',
      'cep',
      'logradouro',
      'numero',
      'bairro',
      'cidade',
      'estado',
      'pais',
      'email',
      'fone',
      'ativo',
    ]

    for (const key of obrigatorio) {
      if (!fildsvalores[key]) {
        AlertaDeMensagem('alerta', `é obrigatório passa o ${key}`)
        return
      }
    }

    console.log('fildsvalores :>> ', fildsvalores);
    try {
      const res = await api.post(`/clientes/novocliente`, fildsvalores)
      if (res.data.status) {
        setAdicionarCliente(false)
        buscarClientes()
        AlertaDeMensagem('sucesso', 'Ação gravada com sucesso', 'Sucesso!', 4000)
      } else {
        AlertaDeMensagem('alerta', res.data.mensagem, 'ATENÇÃO!', 4000)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }

  async function criarDatabase() {

    let banco = document.querySelector('#banco').value
    let user_bd = document.querySelector('#user_bd').value
    let pass_bd = document.querySelector('#pass_bd').value
    let porta_bd = document.querySelector('#porta_bd').value
    let host_bd = document.querySelector('#host_bd').value

    if (!banco || !user_bd || !pass_bd) {
      AlertaDeMensagem('alerta', 'Ação não pode ser gravada, tente novamente', 'ATENÇÃO!', 4000)

      return
    }


    let body = {
      nome_banco: banco,
      user_bd: user_bd,
      pass_bd: pass_bd,
      porta_bd,
      host_bd
    }

    try {
      const res = await api.post(`/clientes/criarbdcliente`, body)
      if (res.data.status) {
        setAdicionarCliente(false)
        buscarClientes()
        AlertaDeMensagem('sucesso', 'Ação gravada com sucesso', 'Sucesso!', 4000)
      } else {
        AlertaDeMensagem('alerta', 'Ação não pode ser gravada, tente novamente', 'ATENÇÃO!', 4000)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }
  function functionCor(index) {
    const cor = ['#2e4a66', '#a98921', '#469cac', '#3b9668'];
    if (index % 4 == 0) {
      return cor[0];
    }
    else if (index % 4 == 1) {
      return cor[1];
    }
    else if (index % 4 == 2) {
      return cor[2];
    }
    else if (index % 4 == 3) {
      return cor[3];
    }
  }
  //upload imagem
  function fileChangedHandler(event) {
    console.log('event :>> ', event);
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0], // É o arquivo da imagem que será redimensionada.
          100, // É o maxWidth da nova imagem redimensionada.
          100, // É o maxHeight da nova imagem redimensionada.
          "JPEG", // É o compressFormat de a nova imagem redimensionada.
          100, // É a qualidade da nova imagem redimensionada.
          0, // É o grau de rotação no sentido horário a ser aplicado à imagem enviada.
          (uri) => {  // É a função callBack do novo URI de imagem redimensionado.
            setImgUsuarioSalvar(uri);
          },
          "base64",// É o tipo de saída da nova imagem redimensionada.
          100,// É o minWidth da nova imagem redimensionada.
          100 // É o minHeight da nova imagem redimensionada.
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  //fildes dos inputs
  const filds = [
    {
      linha: [
        { label: 'Id', value: 'id_cliente', tipo: 'text', select: [], clas: '', clas2: clienteSelecionado != null ? '' : 'ocultar' },
        { label: 'Nome', value: 'nome', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Sobrenome', value: 'sobrenome', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'CPF', value: 'cpf', tipo: 'text', select: [], clas: 'cpf_', clas2: '' },
      ],
      id: '1'
    },
    {
      linha: [
        { label: 'Gênero', value: 'genero', tipo: 'select', select: [{ label: 'Masculino', value: 1 }, { label: 'Feminino', value: 0 }], clas: '', clas2: '' },
        { label: 'Data Nas', value: 'data_nascimento', tipo: 'date', select: [], clas: '', clas2: '' },
        { label: 'Profissão', value: 'profissao', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Ativo', value: 'ativo', tipo: 'select', select: [{ label: 'Sim', value: 1 }, { label: 'Não', value: 0 }], clas: '', clas2: '' },
      ],
      id: '2'
    },
    {
      linha: [
        { label: 'CEP', value: 'cep', tipo: 'text', select: [], clas: 'cep_', clas2: '' },
        { label: 'Logradouro', value: 'logradouro', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Número', value: 'numero', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Bairro', value: 'bairro', tipo: 'text', select: [], clas: '', clas2: '' },
      ],
      id: '3'
    },
    {
      linha: [
        { label: 'Cidade', value: 'cidade', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Estado', value: 'estado', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'País', value: 'pais', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'E-mail', value: 'email', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Fone', value: 'fone', tipo: 'text', select: [], clas: 'fone_', clas2: '' },
      ],
      id: '4'
    },
    {
      linha: [
        { label: 'Plano', value: 'pais', tipo: 'select', select: [{ label: 'Anual', value: 1 }, { label: 'Mensal', value: 2 }], clas: '', clas2: '' },
        { label: 'Banco de dados', value: 'banco', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'User_BD', value: 'user_bd', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Porta_BD', value: 'porta_bd', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Password_BD', value: 'pass_bd', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'Host_BD', value: 'host_bd', tipo: 'text', select: [], clas: '', clas2: '' },
      ],
      id: '5'
    },
  ]

  return (
    <s.ContainerGeral>
    
      {
        !adicionarCliente ?
          <>
            <s.ContaineRow>
              <Pesquisa
                placeholder='Pesquisa de cliente'
                setValue={filtraLista}
              />

              <div id='container__addIcon'>
                <Fa.FaUserPlus
                  onClick={() => { setAdicionarCliente(true) }}
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
              </div>

            </s.ContaineRow>
            <s.ContainerScroll>
              {
                listaClientes.map((c, index) =>
                  <s.ContainerCard cor={functionCor(index)} onClick={() => editarCliente(c)}
                    key={c.id_cliente}>
                    <img src={c.logo != null ? c.logo : noimage} alt='hello' />
                    <s.Greeting >
                      <h1>{c.nome} {c.sobrenome}</h1>
                      <p>Código do cliente: {c.id_cliente}</p>
                      <p>{c.profissao}</p>
                    </s.Greeting>
                  </s.ContainerCard>
                )
              }
            </s.ContainerScroll>
          </>
          :
          <>
            <s.ContaineRow>
              <div id='container__addIcon'>
                <Fa.FaArrowAltCircleLeft
                  onClick={() => { setAdicionarCliente(false) }}
                  className='fa fa-times Icon'
                  aria-hidden='true'
                />
              </div>

              {
                clienteSelecionado &&
                <div id='container__addIcon'>
                  <Fa.FaDatabase
                    onClick={() => { criarDatabase() }}
                    className='fa fa-times Icon'

                    aria-hidden='true'
                  />
                </div>
              }

              <div id='container__addIcon'>
                <Fa.FaSave
                  onClick={() => { gravar() }}
                  className='fa fa-times Icon'

                  aria-hidden='true'
                />
              </div>
            </s.ContaineRow>


            <s.BotaoInsertImg onClick={() => document.querySelector('#FotoPerfilUsuario').click()}>
              <img
                id='logo'
                src={imgUsuarioSalvar ? imgUsuarioSalvar :
                  clienteSelecionado?.logo != null ? clienteSelecionado?.logo :
                    noimage}

              />
              <input
                type="file"
                className="inputs"
                id="FotoPerfilUsuario"
                onChange={e => { fileChangedHandler(e) }}
                style={{ display: "none" }}
              />
            </s.BotaoInsertImg>
            <s.ContainerScroll >

              {
                filds.map(l => {

                  return (
                    <s.ContainerInputPesq key={l.id} className='valores' >
                      {
                        l.linha.map((e, i) => {
                          return (
                            <s.ContainerInputFi key={`${e.value}_${i + 1}`}
                              className={`${e.clas2}`}
                            >
                              <InputKweb
                                className={`salvarbd_ limparinput_ ${e.clas}`}
                                titulo={e.label}
                                type={e.tipo}
                                id={e.value}
                                name={e.value}
                                style={{ width: '100%' }}
                                onChange={(text) => e.value == 'cep' ? setCep(text.target.value) : {}}
                              >
                                {
                                  e.select.map((op, index) =>
                                    <option key={op.value} value={op.value}>
                                      {op.label}
                                    </option>
                                  )
                                }
                              </InputKweb>
                            </s.ContainerInputFi>
                          )
                        })
                      }
                    </s.ContainerInputPesq>
                  )
                })
              }
            </s.ContainerScroll>

          </>
      }

    </s.ContainerGeral>

  )
}



