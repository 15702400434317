import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Home from "../pages/Home/home";
import Clientes from "../pages/clientes/clientes";
import Consultas from "../pages/consultas/consultas";


export default function AppRoutes() {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={'/home'} />} />
            <Route path='/home' element={<Home />} />
            <Route path='/clientes' element={<Clientes/>} />
            <Route path='/consultas' element={<Consultas />} />

            {/* =========================================================================== */}
            {/*CASO VENHA DO LOGIN, ENVIA PARA A HOME, CASO NÃO ENVIA PARA A PAGINA NÃO ENCONTRADA  */}
            <Route path='*' element={<Navigate to={'/'} />} />
        </Routes>
    );
}