import React, { useContext, useEffect, useState } from 'react';
import * as s from './EtiloLogin';
import api from '../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';

export default function Login() {
  const { setToken, setUser, Spinner } = useContext(AuthContext)
  const [perConectado, setPerConectado] = useState((localStorage.getItem('conectado') ? true : false))
  const [lembrar, setLembrar] = useState((localStorage.getItem('login') ? true : false))

  useEffect(() => {
    console.log('ocalStorage. :>> ', localStorage.getItem('conectado'));
  }, [])


  function verificaEnter(key) {
    if (key == 'Enter') {
      fazerlogin()
    }
  }

  async function fazerlogin() {
    Spinner(true)
    let valores = null
    let dados = document.querySelectorAll('#area-login [name]')
   
    for (const i of dados) {
      valores = {
        ...valores,
        [i.id]: i.value //valores
      }
    }


    if (valores.login == '') {
      Spinner(false)
      AlertaDeMensagem('alerta', 'O campo de login é obrigatório', 'Autenticação', 4000)
      return
    }
    if (valores.senha == '') {
      Spinner(false)
      AlertaDeMensagem('alerta', 'O campo de senha é obrigatório', 'Autenticação', 4000)
      return
    }
    // if (valores.site == '') {
    //   Spinner(false)
    //   AlertaDeMensagem('alerta', 'Preencha o campo Site, com o site da escola', 'Autenticação', 4000)
    //   return
    // }

    try {
      const res = await api.post(`/atelogin/login`, valores)
      let dados = res.data.resultado
      if (res.data.status) {
        Spinner(false)
        
        if (perConectado) {
          localStorage.setItem('conectado', 'true');
        } else {
          localStorage.removeItem('user')
        }
        
        if (lembrar) {
          localStorage.setItem('login', valores.login)
          // localStorage.setItem('site', valores.site)
        } else {
          localStorage.removeItem('login')
          // localStorage.removeItem('site')
        }

        localStorage.setItem('idusuario', dados.idusuario);
        localStorage.setItem('@token', dados.token);
        localStorage.setItem('user', JSON.stringify(dados.result[0]))

        setUser(dados.result[0])
        setToken(dados.token)

      } else {
        Spinner(false)
        AlertaDeMensagem('alerta', res.data.mensagem, 'Autenticação', 4000)

      }

    } catch (error) {
      Spinner(false)
      console.log('error :>> ', error);
      AlertaDeMensagem('erro', 'Falha na comunicação com o servidor', 'Autenticação', 4000)

    }
  }

  return (
    <s.ContainerGeral>
      <s.ContainerArea>
        <s.BaseLeft>
          <s.ContainerLogin id='area-login' >
            <s.Titulo>Autenticação </s.Titulo>
            <s.ContainerInput>
              <s.Label cor={'#000'}>
                Login
              </s.Label>
              <s.Input
                placeholder='Digite seu login'
                type='text'
                id='login'
                name='login'
                defaultValue={localStorage.getItem('login') || ''}
                onKeyDown={(e) => verificaEnter(e.key)}
              />
            </s.ContainerInput>

            <s.ContainerInput>
              <s.Label cor={'#000'}>
                Senha
              </s.Label>
              <s.Input

                placeholder='Digite sua senha'
                type='password'
                id='senha'
                name='senha'
                onKeyDown={(e) => verificaEnter(e.key)}
              />
            </s.ContainerInput>

            {/* <s.ContainerInput>
              <s.Label cor={'#000'}>
                Site
              </s.Label>

              <s.Input
                placeholder='Digite o site da escola'
                type='text'
                id='site'
                name='site'
                defaultValue={localStorage.getItem('site') || ''}
                onKeyDown={(e) => verificaEnter(e.key)}



              />
            </s.ContainerInput> */}

            <s.ContainerCheckBox onClick={() => setPerConectado(!perConectado)}>
              {
                perConectado ?
                  <MdCheckBox size={25} color='#024351' />
                  :
                  <MdCheckBoxOutlineBlank size={25} color='#024351' />
              }
              Permanecer conectado
            </s.ContainerCheckBox>

            <s.ContainerCheckBox onClick={() => setLembrar(!lembrar)} >

              {
                lembrar ?
                  <MdCheckBox size={25} color='#024351' />
                  :
                  <MdCheckBoxOutlineBlank size={25} color='#024351' />
              }
              Lembrar login
            </s.ContainerCheckBox>

            <s.BotaoEntrar onClick={() => fazerlogin()} >
              <s.TextoBotao>
                Entrar
              </s.TextoBotao>
            </s.BotaoEntrar>

          </s.ContainerLogin>
        </s.BaseLeft>

        <s.BaseRigth  >
          <s.DivRight1 />
          <s.DivRight2>
            <s.Titulo2 >Kweb Dashboard</s.Titulo2>
            <s.TextoExplicativo>
            Este dashboard foi projetado para facilitar o gerenciamento de 
            múltiplos projetos e tarefas em uma única interface intuitiva.
            </s.TextoExplicativo>

          </s.DivRight2>
        </s.BaseRigth>

      </s.ContainerArea>
    </s.ContainerGeral>
  )
}