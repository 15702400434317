/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { createContext, useState } from 'react';
import { useEffect } from 'react';
export const AuthContext = createContext();

export default function AuthProvider({ children }) {
    const [token, setToken] = useState(localStorage.getItem('@token') || '');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || null))
    const [pesquisaInputNavbar, setPesquisaInputsNavbar] = useState('')
    const [pesquisaAtiva, setPesquisaAtiva] = useState(false)
    const [carrega, setCarrega] = useState(null)
   
    /**
     * Nova Estrutura do Carregamento Global
     * @param {boolean} valor true ou false
    */
    function Spinner(valor) {
        setCarrega(valor)
        // setSpinnerGlobal(valor)
    }

    async function deslogar() {
        localStorage.removeItem('@token')
        localStorage.removeItem('user')
        setUser(null)
        setToken('')
    }


    return (
        <AuthContext.Provider
            value={{
                signed: (!!token && !!user), setToken, deslogar, user, setUser, Spinner,
                pesquisaInputNavbar, setPesquisaInputsNavbar,pesquisaAtiva, setPesquisaAtiva
            }}
        >
            {children}
        </AuthContext.Provider>
    )

}
