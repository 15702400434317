import './navbar.css'
import avatar from '../../img/kleberson.png'
import * as Fa from "react-icons/fa";

const NavBar = ({ statusSidebar, setStatusSidebar, pesquisaAtiva = false, setPesquisa }) => {

    return (

        <nav className='navbar'>
            <div className='container-menu-titulo'>
                {
                    !statusSidebar &&
                    <div className='nav_icon' onClick={() => setStatusSidebar(!statusSidebar)}>
                        <Fa.FaBars
                            onClick={() => setStatusSidebar(!statusSidebar)}
                            className='fa fa-times'
                            id='navbarIcon'
                            aria-hidden='true'
                        />
                    </div>
                }

                <h1>Kweb Dashboard</h1>

            </div>

            <div className='navbar__right'>
                {

                    pesquisaAtiva &&
                    <>
                        <input
                            className='pesquisa__navbar'
                            placeholder='Pesquise aqui'
                            onChange={(text) => setPesquisa(text.target.value)}
                        />
                        <a href='#'>
                            <Fa.FaSearch
                                onClick={() => { }}
                                className='fa fa-times'
                                id='navbarIcon'
                                aria-hidden='true'
                            />
                        </a>


                    </>
                }

                <a href='#'>
                    <Fa.FaClock
                        onClick={() => { }}
                        className='fa fa-times'
                        id='navbarIcon'
                        aria-hidden='true'
                    />
                </a>
                <a href='#'>
                    <img width={30} src={avatar} alt='avatar' />
                </a>

            </div>
        </nav>
    )
}

export default NavBar;