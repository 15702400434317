import styled from "styled-components";
import { cor20, cor21, cor23, vermelho } from "../../styles/coresPadroes";

const corlaranja = '#ffb300';
const corrosa = '#B404AE';
const corverde = '#26a69a';
const corazul = '#0404B4';
const corvermelho = '#ff0000';
const corrose = '#FE642E';
const corcinza = '#6c6b6b';
const corvinho = '#a90329';
const corroxo = '#6200ea';

const color2 = '#9D5FDA';
const color3 = '#2911e2d7';
const color4 = '#43bbdf';
const color5 = '#156114';
const color6 = '#47b345fc';
const color7 = '#f87a26';
const color8 = '#ff1d1de0';
const color9 = '#187216';
const color10 = '#770909';
const color11 = '#731bcbd7';

// geral
export const ContainerGeral = styled.div`
    width: 100%;
    main {
        background-color: #f3f4f6;
        grid-area: main;
        overflow-y: auto;
    }
    .main__container{
        padding: 20px 35px;
    }
    .main__title{
        display: flex;
        align-items: center;

        img {
            max-height: 100px;
            object-fit: contain;
            margin-right: 20px;
        }
    }
   
    .main__greeting  {
        
        h1 {
            font-size: 24px;
            color: #2e4a66;
            margin-bottom: 5px;
        }
        p {
            font-size: 14px;
            font-weight: 700;
            color: #a5aaad;

        }
        
    }
    .main__cards  {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 30px;
        margin: 20px 0;
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        /* height: 70px; */
        padding: 20px;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff ;

    }

    .card_inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 25px;
        }
    }

    .charts {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        margin-top: 50px;
    }

    .charts__left {
        padding: 20px;
        border-radius: 5px;
        background-color: #fff;
        border-radius: 5px 5px 13px #ededed, -5px -5px 13px #fff;
    }
    .charts__left__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        div {

            h1{
                font-size: 24px;
                color: #2e4a66;
                margin-bottom: 5px;
            }
            p {
                font-size: 14px;
                color: #a5aaad;
                font-weight: 700;
            }
    
        }

        svg {
               
                color: #fff;
                font-size: 30px;
                background: #ffc100;
                border-radius: 200px 0px 200px 200px;
                -moz-border-radius: 200px 0px 200px 200px;
                -webkit-border-radius:200px 0px 200px 200px;
                border: 0px solid #000 ;
                padding: 5px;
            }

    }

    .charts__right {
        padding: 20px;
        border-radius: 5px;
        background-color: #fff;
        border-radius: 5px 5px 13px #ededed, -5px -5px 13px #fff;

    }

    .charts__right__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        div {

            h1{
                font-size: 24px;
                color: #2e4a66;
                margin-bottom: 5px;
            }
            p {
                font-size: 14px;
                color: #a5aaad;
                font-weight: 700;
            }
    
        }

        svg {
               
                color: #fff;
                font-size: 30px;
                background: #39447a;
                border-radius: 200px 0px 200px 200px;
                border: 0px solid #000 ;
                padding: 5px;
            }

    }


    .charts__right__cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 50px;
    }

    .card1 {
        background: #d1ecf1;
        color: #35a4ba;
        text-align: center;
        padding: 25px;
        border-radius: 5px;
        font-size: 14px;
    }
    .card2 {
        background: #e2f9ee;
        color: #38e1b0;
        text-align: center;
        padding: 25px;
        border-radius: 5px;
        font-size: 14px;
    }
    .card3 {
        background: #d6d8d9;
        color: #3a3e41;
        text-align: center;
        padding: 25px;
        border-radius: 5px;
        font-size: 14px;
    }
    .card4 {
        background: #fddcdf;
        color: #f65a6f;
        text-align: center;
        padding: 25px;
        border-radius: 5px;
        font-size: 14px;
    }

    @media only screen and (max-width: 855px) {
        .main__cards  {
            grid-template-columns: 1fr ;
            gap: 10px;
            margin-bottom: 0;
        }
        
        .charts {
            grid-template-columns: 1fr;
            margin-top: 30px;
            justify-content: center;
        }
        
        .charts__right__cards {
            grid-template-columns: 1fr;
            align-items: center;
            margin-top: 30px;
        }

    }

`;
