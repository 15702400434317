import styled from 'styled-components';

//Estilos do footer do login
export const ContainerGeral = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    background: linear-gradient(90.29deg, #025A6C 17%, #024351 74.93%);
    overflow: hidden;
`;
export const LoadChamarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  flex-direction: column;
  font-size: 3.5rem;
  font-family: Inter, Rajdhani, sans-serif;
  
  h1 {
    color: #fff;
    font-size: 20px;
  }

`;




