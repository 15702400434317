import './sidebar.css'
import logo from '../../img/profile.png'
import * as Fa from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar = ({ statusSidebar, setStatusSidebar, deslogar, user }) => {
    const { pathname } = useLocation()


    const navigate = useNavigate()

    const linksAdm = [
        {
            title: 'ADMIN',
            links: [
                {
                    id: 'area_administrativa',
                    classe: <Fa.FaTachometerAlt />,
                    title: 'Área Administrativa',
                    link: '/#',
                },
                {
                    id: 'clientes',
                    classe: <Fa.FaBuilding />,
                    title: 'Clientes',
                    link: '/clientes',
                },
                {
                    id: 'produtos',
                    classe: <Fa.FaArchive />,
                    title: 'Produtos',
                    link: '/#',
                },
                {
                    id: 'categorias',
                    classe: <Fa.FaBars />,
                    title: 'Categorias',
                    link: '/#',
                },
                {
                    id: 'pedidos',
                    // classe: 'fa fa-cutlery',
                    classe: <Fa.FaProductHunt />,
                    title: 'Pedidos',
                    link: '/#',
                },
                {
                    id: 'consultas',
                    // classe: 'fa fa-cutlery',
                    classe: <Fa.FaDatabase />,
                    title: 'Consultas',
                    link: '/consultas',
                },
            ]
        },
        {
            title: 'Pessoas',
            links: [
                {
                    id: 'administradores',
                    classe: <Fa.FaUserLock />,
                    title: 'Administradores',
                    link: '/#',
                },
                {
                    id: 'usuarios',
                    classe: <Fa.FaUserCircle />,
                    title: 'Usuários',
                    link: '/#',
                },
                {
                    id: 'pagamentos_custos',
                    classe: <Fa.FaMoneyBill />,
                    title: 'Pagamentos e custos',
                    link: '/#',
                },
                {
                    id: 'plataforma',
                    classe: <Fa.FaTasks />,
                    title: 'A plataforma',
                    link: '/#',
                },
                {
                    id: 'politica_privacidade',
                    classe: <Fa.FaFileAlt />,
                    title: 'Política de privacidade',
                    link: '/#',
                },
                {
                    id: 'sair',
                    classe: <Fa.FaPowerOff />,
                    title: 'Sair',
                    link: '/#',
                },
            ]
        },
    ]
    function mudaFoco(id, link) {
        if (id == 'sair') {
            deslogar()
        }
        let ids = document.querySelectorAll(`.sidebar__menu [id]`)
        //aqui remove todos que tem a classe
        for (const elemento of ids) {
            elemento.classList.remove('active_menu_link');
        }
        navigate(link)
    }


    return (
        <div className={statusSidebar ? 'sidebar-responsive' : ''}
            id='sidebar'>
            <div className='sidebar__title' >
                <div className='sidebar__img' >
                    <img src={user?.avatar || logo} alt='logo' />
                    <h1>{user?.user}</h1>
                </div>

                <Fa.FaTimes
                    onClick={() => setStatusSidebar(false)}
                    className='fa fa-times'
                    id='sidebarIcon'
                    aria-hidden='true'
                />

            </div>
            <div className='sidebar__menu'>
                <div
                    id='home'
                    className={`sidebar__link ${pathname == '/home' ? 'active_menu_link' : ''}`} >
                    <Fa.FaMinusSquare />
                    <a onClick={() => mudaFoco('home', 'home')}>Home</a>
                </div>

                {
                    linksAdm.map((d, ind) =>
                        <>
                            <h2>{d.title}</h2>
                            {
                                d.links.map((e, i) =>
                                    <div
                                        id={e.id}
                                        key={e.id}
                                        className={`${e.id != 'sair' ? 'sidebar__link' : 'sidebar__logout'} ${pathname == e.link ? 'active_menu_link' : ''}`}
                                    >
                                        {e.classe}
                                        <a
                                            onClick={() => mudaFoco(e.id, e.link)}
                                        >{e.title}</a>
                                    </div>
                                )
                            }

                        </>
                    )
                }



            </div>


        </div>
    )
}

export default Sidebar;