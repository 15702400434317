import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloconsultas'
import api from './../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import Pesquisa from '../../components/pesquisa/pesquisa';
import * as Fa from "react-icons/fa";
import InputKweb from '../../components/InputPersonalizado/input_personalizavel';
import axios from 'axios';
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';
import noimage from '../../img/profile.png'

export default function Consultas() {
  const { user, setPesquisaAtiva, Spinner } = useContext(AuthContext)
  const [consultaSelecionada, setConsultaSelecionada] = useState(null)
  const [listaConsultas, setListaConsultas] = useState([])
  const [listaConsultas2, setListaConsultas2] = useState([])
  const [adicionarConsulta, setAdicionarConsulta] = useState(false)

  //entra aqui quando é edição de cliente
  useEffect(() => {
    if (consultaSelecionada) {
      for (const key in consultaSelecionada) {
        const valor = consultaSelecionada[key];
        try { document.getElementById(key).value = valor } catch (error) { null }
      }
    }
  }, [consultaSelecionada])

  useEffect(() => {
    if (!adicionarConsulta) {
      setConsultaSelecionada(null)
    }
  }, [adicionarConsulta])

  function editarCliente(cliente) {
    setConsultaSelecionada(cliente)
    setAdicionarConsulta(true)
  }

  useEffect(() => {
    setPesquisaAtiva(false)
    buscarConsultas()
  }, [])

  function filtraLista(texto) {
    let novoArray = listaConsultas2.filter(
      e => e.titulo.toLowerCase().includes(texto.toLowerCase()) ||
        e.auto.toString().includes(texto) ||
        e.id.toString().includes(texto)
    )
    setListaConsultas(novoArray)
  }
  async function buscarConsultas() {

    try {
      const res = await api.get(`/consultas/consultasauto`)
      if (res.data.status) {
        setListaConsultas(res.data.resultado)
        setListaConsultas2(res.data.resultado)
      } else {
        setListaConsultas([])
        setListaConsultas2([])
        AlertaDeMensagem('alerta', 'Não existe nenhum cliente cadastrado, faça seu primeiro cadastro', 'ATENÇÃO', 4000)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }
  async function gravar() {
    let elementos = document.querySelectorAll(`.valores [name]`)
    let fildsvalores = null

    for (const fild of elementos) {
      let valor = document.querySelector(`#${fild.id}`).value
      fildsvalores = {
        ...fildsvalores,
        [fild.name]: valor
      }
    }

    let obrigatorio = [
      'titulo',
      'auto',
      'consulta'
    ]
    for (const key of obrigatorio) {
      if (!fildsvalores[key]) {
        AlertaDeMensagem('alerta', `é obrigatório passa o ${key}`)
        return
      }
    }

    try {
      const res = await api.post(`/consultas/novaconsulta`, fildsvalores)
      if (res.data.status) {
        setAdicionarConsulta(false)
        buscarConsultas()
        AlertaDeMensagem('sucesso', 'Ação gravada com sucesso', 'Sucesso!', 4000)
      } else {
        AlertaDeMensagem('alerta', 'Ação não pode ser gravada, tente novamente', 'ATENÇÃO!', 4000)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }
  function functionCor(index) {
    const cor = ['#2e4a66', '#a98921', '#469cac', '#3b9668'];
    if (index % 4 == 0) {
      return cor[0];
    }
    else if (index % 4 == 1) {
      return cor[1];
    }
    else if (index % 4 == 2) {
      return cor[2];
    }
    else if (index % 4 == 3) {
      return cor[3];
    }
  }

  //fildes dos inputs
  const filds = [
    {
      linha: [
        { label: 'Id', value: 'id', tipo: 'text', select: [], clas: '', clas2: consultaSelecionada != null ? '' : 'ocultar' },
        { label: 'titulo', value: 'titulo', tipo: 'text', select: [], clas: '', clas2: '' },
        { label: 'auto', value: 'auto', tipo: 'select', select: [{ label: 'Sim', value: 1 }, { label: 'Não', value: 0 }], clas: '', clas2: '' },
      ],
      id: '1'
    },
    {
      linha: [
        { label: 'Consulta', value: 'consulta', tipo: 'textarea', select: [], clas: '', clas2: '' },
      ],
      id: '2'
    },

  ]

  return (
    <s.ContainerGeral>
      {
        !adicionarConsulta ?
          <>
            <s.ContaineRow>
              <Pesquisa
                placeholder='Pesquisar consultas'
                setValue={filtraLista}
              />
              <div id='container__addIcon'>
                <Fa.FaUserPlus
                  onClick={() => { setAdicionarConsulta(true) }}
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
              </div>
            </s.ContaineRow>
            <s.ContainerScroll>
              {
                listaConsultas.map((c, index) =>
                  <s.ContainerCard cor={functionCor(index)} onClick={() => editarCliente(c)}
                    key={c?.id}>
                    {/* <img src={c?.logo != null ? c?.logo : noimage} alt='hello' /> */}
                    <s.GreetingCircle cor={functionCor(index)}>
                      <h1>{c?.id}</h1>
                    </s.GreetingCircle>
                    <s.Greeting >
                      <h1>{c?.titulo}</h1>
                      <p>Consulta auto: {c?.auto == 1 ? 'SIM' : 'NÃO'}</p>
                    </s.Greeting>
                  </s.ContainerCard>
                )
              }
            </s.ContainerScroll>
          </>
          :
          <>
            <s.ContaineRow>
              <div id='container__addIcon'>
                <Fa.FaArrowAltCircleLeft
                  onClick={() => { setAdicionarConsulta(false) }}
                  className='fa fa-times Icon'
                  aria-hidden='true'
                />
              </div>
              {/* <p className='voltar'>Voltar</p> */}
              <div id='container__addIcon'>
                <Fa.FaSave
                  onClick={() => { gravar() }}
                  className='fa fa-times Icon'
                  aria-hidden='true'
                />
              </div>
            </s.ContaineRow>
            <s.ContainerScroll >

              {
                filds.map(l => {

                  return (
                    <s.ContainerInputPesq key={l.id} className='valores' >
                      {
                        l.linha.map((e, i) => {
                          return (
                            <s.ContainerInputFi key={`${e.value}_${i + 1}`}
                              className={`${e.clas2}`}
                            >
                              <InputKweb
                                className={`salvarbd_ limparinput_ ${e.clas}`}
                                titulo={e.label}
                                type={e.tipo}
                                id={e.value}
                                name={e.value}
                                style={{ width: '100%' }}
                                onChange={(text) => e.value == 'cep' ? setCep(text.target.value) : {}}
                              >
                                {
                                  e.select.map((op, index) =>
                                    <option key={op.value} value={op.value}>
                                      {op.label}
                                    </option>
                                  )
                                }
                              </InputKweb>
                            </s.ContainerInputFi>
                          )
                        })
                      }
                    </s.ContainerInputPesq>
                  )
                })
              }
            </s.ContainerScroll>

          </>
      }

    </s.ContainerGeral>

  )
}



