
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloHome'
import * as Fa from "react-icons/fa";
import avatar from '../../img/profile.png'
import Chart from '../../components/charts/charts';
import { AuthContext } from '../../contexts/authContext';


export default function Home() {
  const { user, setPesquisaAtiva } = useContext(AuthContext)

  useEffect(() => {
    setPesquisaAtiva(false)
  }, [])

  return (
    <s.ContainerGeral>
      <main>
        <div className='main__container'>
          <div className='main__title' >
            <img src={user.avatar || avatar} alt='hello' />
            <div className='main__greeting'>
              <h1>Olá {user?.nome}</h1>
              <p>Bem vindo ao seu painel</p>
            </div>
          </div>

          {/* Cards  */}
          <div className='main__cards'>

            <div className='card' >
              <Fa.FaFileAlt className='fa-2x text-lightblue' />
              <div className='card_inner'>
                <p className='text-primary-p' >Número de pedidos</p>
                <span className='font-bold text-file' >578</span>
              </div>
            </div>

            <div className='card' >
              <Fa.FaMoneyBillAlt className='fa-2x text-red' />
              <div className='card_inner'>
                <p className='text-primary-p' >Pagamentos</p>
                <span className='font-bold text-file' >R$578</span>
              </div>
            </div>

            <div className='card' >
              <Fa.FaArchive className='fa-2x text-yellow' />
              <div className='card_inner'>
                <p className='text-primary-p' >Número de produtos</p>
                <span className='font-bold text-file' >500</span>
              </div>
            </div>

            <div className='card' >
              <Fa.FaBars className='fa-2x text-green' />
              <div className='card_inner'>
                <p className='text-primary-p' >Categorias</p>
                <span className='font-bold text-file' >40</span>
              </div>
            </div>

          </div>

          <div className='charts'>

            <div className='charts__left' >
              <div className='charts__left__title' >
                <div >
                  <h1>Daily Reports</h1>
                  <p>Recife, Pernambuco, BR</p>
                </div>
                <Fa.FaUsb className='fa-2x text-lightblue' />
              </div>
              <Chart />
            </div>

            <div className='charts__right' >
              <div className='charts__right__title' >
                <div >
                  <h1>Daily Reports</h1>
                  <p>Recife, Pernambuco, BR</p>
                </div>
                <Fa.FaChartArea className='fa-2x text-lightblue' />
              </div>

              <div className='charts__right__cards'>

                <div className='card1'>
                  <h1>Lucro</h1>
                  <p>R$2500</p>
                </div>

                <div className='card2'>
                  <h1>Pagamentos</h1>
                  <p>R$250,00</p>
                </div>

                <div className='card3'>
                  <h1>Custos e hospedagens</h1>
                  <p>R$150,00</p>
                </div>

                <div className='card4'>
                  <h1>Banco de dados</h1>
                  <p>R$180,00</p>
                </div>


              </div>

            </div>


          </div>

        </div>
      </main>
    </s.ContainerGeral>

  )
}

