import styled from 'styled-components';
import imgRigth2 from '../../assets/fundoRigth2.png';
import imgLeft from '../../assets/fudo1.png';

//Estilos do footer do login
export const ContainerGeral = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    /* background: linear-gradient(90.29deg, #025A6C 17%, #024351 74.93%); */
    background-color: #e6f5ff;
    overflow: hidden;
`;
export const ContainerArea = styled.div`
    display: flex;
    background-color: #fff;
    min-height: 600px;
    min-width: 1000px;
    border-radius: 20px;
    
    @media (max-width: 1000px) {
        min-width: 100%;
        min-height: 100%;
        flex-direction: column-reverse;
    }
        
    

`;
export const BaseLeft = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #EEF8FF;
    min-height: 300px;
    width: 500px;
    border-radius: 20px;
    padding: 10px;
    background-image: url(${imgLeft});
    background-repeat:no-repeat;

    @media (max-width: 1000px) {
        flex: 2;
        width: 100%;
        min-width: 100%;
        min-height: auto;
        flex-direction: column;
        background-image: none;
        padding: 0px;
        background-color: red;
    }

    @media (max-width: 600px) {
        
       padding: 0px;
    }
`;
export const ContainerLogin = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 400px;
    min-height: 450px;
    border-radius: 20px;
    box-shadow: 0px 0px 6px .5px rgba(0, 0, 0, 0.2);
    padding: 30px;

    @media (max-width: 1000px) {
        flex: 1;
        width: 100%;
        min-width:100%;
        min-height: auto;
        flex-direction: column;
        /* justify-content: center; */
        border-radius: 0px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    }

    
`;
export const Titulo = styled.h1`
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 15px;
    color: #024351;
`;
export const Titulo2 = styled.h1`
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 15px;
    color: #fff;

    @media (max-width: 1000px) {
       padding-top: 10px;
    }
`;
export const TextoExplicativo = styled.p`
    /* font-weight: bold; */
    font-size: 16px;
    margin-bottom: 15px;
    color: #fff;
`;

///////////////////////////////// BaseRigth

export const BaseRigth = styled.div`
    display: flex;
    background-color: #024351;
    min-height: 300px;
    width: 500px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-image: url(${imgRigth2});
    background-repeat:no-repeat;

    @media (max-width: 1000px) {
        
        width: 100%;
        min-width: 100%;
        min-height: auto;
        flex-direction: column;
        background-image: none;
        align-items: center;

        border-top-right-radius: 0px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        
    }



`;
export const DivRight1 = styled.div`
    width: 166px;

    @media (max-width: 1000px) {
       display: none;
    }
`;
export const DivRight2 = styled.div`
    width: 334px;
    padding: 20px;

    @media (max-width: 1000px) {
       width: 100%;
    }
`;





// Estilos do Input 
export const ContainerInput = styled.div`
    width: 100%;
    height: auto;
`;
export const ContainerCheckBox = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    color: #9a9a9a;
    cursor: pointer;
`;
export const Label = styled.p`
    color: ${p => p.cor};
    width: 100%;
    border-color: #000;
    margin-bottom: 5px;
`;
export const Input = styled.input`
    border: 2px solid #025A6C;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 2px .5px rgba(0, 0, 0, 0.2);
    padding: 5px;
`;

export const BotaoEntrar = styled.div`
    background-color: #024351;
    width: 100%;
    height: 50px;
    margin: 10px 0px;
    border-radius: 5px;
    box-shadow: 0px 0px 2px .5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover{
        opacity: .5;
    }

`;
export const TextoBotao = styled.p`
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: 700;
`;




