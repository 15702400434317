import AuthProvider from "./contexts/authContext";
import GlobalStyle from "./styles/global";
import { BrowserRouter as Router } from 'react-router-dom';
import ContainerGlobal from "./components/Container/ContainerGlobal";
import Rotas from "./routes/rotas";

function App() {

  return (
    <AuthProvider>
      <GlobalStyle />
      <Router>
        <ContainerGlobal >
          <Rotas />
        </ContainerGlobal>
      </Router>
    </AuthProvider>
  );
}

export default App;
