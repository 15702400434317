
import * as s from './estiloContainerGlobal'
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import Sidebar from '../sidebar/sidebar';
import NavBar from '../navibar/navbar';
import { ToastContainer } from 'react-toastify';

export default function ContainerGlobal({ children }) {
    const { signed, deslogar, pesquisaAtiva, setPesquisaInputsNavbar,user  } = useContext(AuthContext)
    const [statusSidebar, setStatusSidebar] = useState(false);

    return (
        <s.ContainerGeral >
            <ToastContainer position="bottom-right" pauseOnHover={false} />
            <s.MainNavSidebar  >
                {
                    signed && statusSidebar &&
                    <Sidebar 
                    statusSidebar={statusSidebar} 
                    setStatusSidebar={setStatusSidebar} 
                    deslogar={deslogar} 
                    user={user}

                    />
                }
                <s.Main>
                    {
                        signed &&
                        <NavBar
                            statusSidebar={statusSidebar}
                            setStatusSidebar={setStatusSidebar}
                            pesquisaAtiva={pesquisaAtiva} 
                            setPesquisa={setPesquisaInputsNavbar}
                            />
                    }
                    {children}
                </s.Main>
            </s.MainNavSidebar>
        </s.ContainerGeral>
    );
}