import React, { useContext, useEffect, useState } from 'react';
import * as s from './styles';
import api from '../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import { useNavigate } from "react-router-dom";
import Lottie from 'react-lottie-player'
import novoSpiner from './agenda2.json'

export default function PreLoad() {

    const navigate = useNavigate()
    const { setUser, setToken } = useContext(AuthContext)

    useEffect(() => {
        checkTokenPadrao()
    }, [])

    /**
     * Verifica o token do aparelho para salvar na state
     */
    async function checkTokenPadrao() {

        const token = localStorage.getItem('@token');
        console.log('token :>> ', token);

        try {

            if (token != null) {

                const conectado = localStorage.getItem('conectado');

                if (conectado && conectado == 'true') {

                    let d = localStorage.getItem(user)
                    setUser(d);
                    setToken(token);

                    // const res = await api.post(`Usuario/dados`);
                    // console.log('res :>> ', res);
                    // let dados = res.data.resultado;

                    // if (res.data.status) {
                
                    //     localStorage.setItem('@token', dados.token);
                    //     localStorage.setItem('user', JSON.stringify(dados.result[0]));
                    //     setUser(dados.result[0]);
                    //     setToken(dados.token);
                        
                    // } else {
                    //     localStorage.removeItem("@token");
                    //     navigate('/login');
                    // }

                } else {
                    localStorage.removeItem("@token");
                    localStorage.removeItem('conectado');
                    navigate('/login');
                }

            } else {
                // localStorage.removeItem("@token");
                navigate('/login');
            }

        } catch (error) {
            console.log('error :>> ', error);
            // localStorage.removeItem("@token");
            navigate('/login');
        }

    }


    return (
        <s.ContainerGeral>
            <s.LoadChamarContainer>
                <Lottie
                    loop
                    animationData={novoSpiner}
                    play
                    style={{ width: 400, height: 200 }}
                />
                <h1>Verificando autenticação...</h1>
            </s.LoadChamarContainer>
        </s.ContainerGeral>
    )
}